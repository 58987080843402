//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'
export default {
  data(){
	return {
		list:{},
		orderId:"",
		indications:"",
		time:"",
		physicals:"",
	}
  },
  mounted(){
	this.orderId = this.$route.params.orderId;
	this.getDetail()
  },
  methods:{
		getIndex(){
			this.$router.push('sing-out')
		},
		// 富文本处理
		showHtml(str){
			return str
			.replace(str ? /&(?!#?\w+;)/g : /&/g, '&amp;')
			.replace(/&lt;/g,"<")
			.replace(/&gt;/g,">")
			.replace(/&quot;/g,"\"")
			.replace(/&#39;/g, "\'")
			.replace(/&amp;nbsp;/g,'\u3000')
		},

		getDetail(){
			axios.get('https://milai.sdmlswkj.com/api/62b91679244d8',{params:{pid:this.orderId}}).then(res => {
				this.list = res.data.data
				this.indications = this.showHtml(res.data.data.project_desc)
				this.time = this.showHtml(res.data.data.length_of_stay)
				this.physicals = this.showHtml(res.data.data.physicals_time)
			}).catch(err => {
				console.log(err)
			})
		},
		//时间戳转换带小时分
		formatTime(curTime, type) {
			if (!curTime) {
				return ''
			}
			var nd = new Date(curTime)
			var y = nd.getFullYear()
			var mm = nd.getMonth() + 1
			var d = nd.getDate()
			var h = nd.getHours()
			var m = nd.getMinutes()
			if (mm < 10) {
				mm = '0' + mm
			}
			if (d < 10) {
				d = '0' + d
			}
			if (h < 10) {
				h = '0' + h
			}
			if (m < 10) {
				m = '0' + m
			}
			if (type == 'MM-DD') {
				return mm + '-' + d
			} else if (type == 'yyyy-MM-dd') {
				return y + '-' + mm + '-' + d
			} else if (type == 'week') {
				return y + '年' + mm + '月' + d + '日　' + weekDay[nd.getDay()]
			} else if (type == 'MM-DD&week') {
				return mm + '-' + d + '　' + weekDay[nd.getDay()]
			} else if (type == 'MM-DD&hh:mm') {
				return mm + '-' + d + ' ' + h + ':' + m
			} else if (type == '年月日') {
				return y + '年' + mm + '月' + d + '日　'
			} else if (type == 'yyyy.MM.dd&hh:mm') {
				return y + '.' + mm + '.' + d + ' ' + h + ':' + m
			}
			else if (type == 'yyyy.MM.dd&hh:mm:ss') {
				return y + '.' + mm + '.' + d + ' ' + h + ':' + m + ':' + s
			}
			return y + '-' + mm + '-' + d + ' ' + h + ':' + m
		},
	}
}
 